<template>
  <div class="">
    <div class="w-full flex text-c14 font-c4 text-left items-center item">
      <div class="w-1/6">
        {{ new Date(item.created_at).toLocaleDateString() }}
      </div>

      <div class="w-1/6 ml-4">
        {{ refactorAmount(item.amount, '.') }} F
      </div>

      <div class="w-1/6 ml-4">
       <span v-if="item.type === 'MOBILE_MONEY'">Mobile money</span>
        <span v-if="item.type === 'BANK_CHECK'">Chèque</span>
        <span v-if="item.type === 'CASH'">Espèce</span>
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.sender.agency">{{ item.sender.agency.label }}</span>

        <span v-if="!item.sender.agency">
          <span v-if="item.sender.person">{{ item.sender.person.fullname }}</span>
          <span v-if="!item.sender.person">-</span>
        </span>
      </div>

      <div class="w-1/6 ml-4">
        {{ item.receiver.person.fullname }}
      </div>

      <div class="w-1/6 ml-4">
        <div class="text-c16">
          {{ item.code }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../assets/icons/entreprise.svg'
import email from '../../../assets/icons/email.svg'
import down from '../../../assets/icons/down.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        entreprise,
        email,
        down
      },
    }
  },

  methods: {
    refactorAmount (a, b) {
      a = '' + a;
      b = b || ' ';
      let c = '',
          d = 0;
      while (a.match(/^0[0-9]/)) {
        a = a.substr(1);
      }
      for (let i = a.length-1; i >= 0; i--) {
        c = (d !== 0 && d % 3 === 0) ? a[i] + b + c : a[i] + c;
        d++;
      }
      return c;
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  padding-left: 10px;
}
</style>
