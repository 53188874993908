<template>
  <div class="p-10 pr-16 all">
    <success v-if="activeSuccess" :message="message" :activation="activeSuccess"  @oga="activeSuccess = false"/>
    <alerte :activation="activeAlerte" v-if="activeAlerte" @oga="retourAlerte"/>
    <popupCharge :activation="charge" v-if="charge"/>
    <div>
      <div
          class="flex"
      >
        <div class="w-3/4 h-20 flex text-left text-c36 items-center font-c6">
          <div class="ml-4">
           Validation des transferts
          </div>
        </div>

        <div class="w-1/4 pr-6">
          <bouton label="Valider" background="#DDDDDD" color="black" class="w-full ml-6" v-if="selectedItem.length === 0"/>
          <bouton label="Valider" class="w-full ml-6" @oga="activeAlerte = true" v-if="selectedItem.length > 0"/>
        </div>

<!--        <div class="w-3/5">-->
<!--          <div class="flex justify-end w-full">-->
<!--            <div class="w-1/4 ml-4">-->
<!--              <bouton-->
<!--                  :icon="icons.calendar"-->
<!--                  iconHeight="17"-->
<!--                  iconWidth="17"-->
<!--                  height="54px"-->
<!--                  label="Période"-->
<!--                  color="black"-->
<!--                  size="14px"-->
<!--                  radius="5px"-->
<!--                  class="w-full"-->
<!--                  background="white"-->
<!--                  border="1px solid #DCDCE4"-->
<!--                  @info="activePeriode =! activePeriode"-->
<!--              />-->
<!--            </div>-->

<!--            <div class="w-1/4 ml-4">-->
<!--              <multiselect-->
<!--                  :icon="icons.filter"-->
<!--                  height="54px"-->
<!--                  background="white"-->
<!--                  :icon-width="25"-->
<!--                  value-t="Type"-->
<!--                  :option="optionType"-->
<!--              />-->
<!--            </div>-->

<!--            <div class="w-2/4 ml-4">-->
<!--              <multiselect-->
<!--                  :icon="icons.filter"-->
<!--                  height="54px"-->
<!--                  background="white"-->
<!--                  :icon-width="25"-->
<!--                  value-t="Moyen d'opération"-->
<!--                  :option="optionMoyen"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>

<!--      <div class="flex justify-end items-center text-c16 text-90 w-full mt-6">-->
<!--        <div class="flex justify-end items-center w-2/4" v-if="activePeriode">-->
<!--          <div>Du</div>-->
<!--          <datepicker height="51px" class="w-1/3 ml-4" placeholder=""/>-->
<!--          <div class="ml-6">Au</div>-->
<!--          <datepicker height="51px" class="w-1/3 ml-4" placeholder=""/>-->
<!--        </div>-->

<!--        <bouton label="Effectuer une opération" class="w-1/4 ml-6" @info="goto('/transfere')"/>-->
<!--      </div>-->

      <div class="mt-8 pl-4">
        <global @info="retourSelect" v-if="!charge"/>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import search from '../../assets/icons/search.svg'
import calendar from '../../assets/icons/calendar.svg'
import filter from '../../assets/icons/filter.svg'
// import multiselect from '../../component/helper/form/multiselect'
// import datepicker from '../../component/helper/form/datePicker'
import global from '../../component/cdevs/validation/globalData'
import alerte from '../../component/popup/alertPaye'
import popupCharge from '../../component/popup/popupCharge'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'

export default {
  name: "Index",

  components: {
    bouton,
    // multiselect,
    global,
    // datepicker
    alerte,
    popupCharge
  },

  data () {
    return {
      icons: {
        back,
        search,
        calendar,
        filter
      },
      activePeriode: false,
      optionType: ['Transfère', 'Remise'],
      optionMoyen: ['Mobile money', 'Espèce', 'Chèque'],
      selectedItem: [],
      activeAlerte: false,
      activeSuccess: false,
      message: 'Transfere effectué avec succès',
      charge: false
    }
  },

  created () {
  },

  methods: {
    retour () {
      window.history.back()
    },

    goto (index) {
      this.$router.push(index)
    },

    retourSelect (answer) {
      this.selectedItem = answer
    },

    retourAlerte(answer) {
      if (answer) {
        this.sendValidation()
      }
      this.activeAlerte = false
    },

    sendValidation () {
      this.charge = true
      let tab = []
      for (let item in this.selectedItem) {
        tab.push(this.selectedItem[item].id)
      }
      let obj = {
        transfers: tab
      }

      http.post(apiroutes.baseURL + apiroutes.validateTransfere, obj)
          .then(response => {
            console.log('success')
            console.log(response)
            this.charge = false
            this.activeSuccess = true
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}

</style>
