<template>
  <popupCharge :activation="charge" v-if="charge"/>
  <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8 pl-8">

    <div class="mt-4">
      <div class="all">
        <div class="flex">
<!--          <radio class="mr-4" @click="activeAll"/>-->
          <div class="w-full flex text-90 text-c14 font-c5 text-left mb-6 pl-8">

            <div class="w-1/6">
              Date
            </div>
            <div class="w-1/6 ml-4">
              Montant
            </div>
            <div class="w-1/6 ml-4">
              Type de transfert
            </div>
            <div class="w-1/6 ml-4 text-left">
              Caisse crédité
            </div>
            <div class="w-1/6 ml-4">
              Destinataire
            </div>
            <div class="w-1/6 ml-4">
              Code de transfère
            </div>
          </div>
        </div>


        <div class="diviser" />

        <div
          v-if="charge && devis.length === 0"
          class="flex justify-center mt-16"
        >
          <easy-spinner
            type="dots"
            size="70"
          />
        </div>

        <div v-if="devis.length === 0" class="mt-10 text-90 mb-10">Aucun transfère en attente de validation pour le moment</div>

        <div
          v-for="(item, index) in devis"
          :key="index"
        >
          <div class="mt-5" v-if="item.code !== 'TR-001045' && item.code !== 'TR-001044'">
            <div
              v-if="index > 0"
              class="diviser mt-5"
            />
            <div class="flex mt-5 items-center">
              <radio class="mr-4" :all="allSelect" @click="selected(item)"/>
              <liste
                class="w-full"
                :item="item"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import liste from './liste'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import radio from '../../helper/form/radio'
import popupCharge from '../../popup/popupCharge'

export default {
  name: "Index",
  components: {
    liste,
    radio,
    popupCharge,
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down
      },
      devis: [],
      charge: true,
      allSelect: false,
      dataSelect: [],
      activeAlerte: false
    }
  },

  created () {
    this.allData()
  },

  methods: {
    allData () {
      http.get(apiroutes.baseURL + apiroutes.allValidation)
          .then(response => {
            console.log('data')
            console.log(response)
            this.devis = response
            this.charge = false
            this.$emit('user', response)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourAlerte(answer) {
      this.activeAlerte = answer
    },

    retourTransfere (answer) {
      if (answer) {
        this.activeBillet = true
      }
      this.activeTransfere = false
    },

    activeAll () {
      this.allSelect =! this.allSelect
      if (this.allSelect) {
        let tab = []
        for (let item in this.devis){
          tab.push(this.devis[item])
        }
        this.dataSelect = tab
      } else {
        this.dataSelect = []
      }
      this.sender()
    },

    sender () {
      this.$emit('info', this.dataSelect)
    },

    selected (item) {
      let exist = null
      if(this.dataSelect.length > 0){
        for (let i in this.dataSelect) {
          if (this.dataSelect[i].id === item.id) {
            exist = i
            this.dataSelect.splice(i, 1)
          }
        }

        if (exist === null) {
          this.dataSelect.push(item)
        }
      } else {
        this.dataSelect.push(item)
      }
      // console.log(this.dataSelect)
      this.sender()
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
